import './assets/styles/App.css';
import goliatOleo from './assets/images/goliat_al_oleo.png';
import goliat from './assets/images/goliat.jpg'
import graficaEstambres from './assets/images/erp.png'

import * as React from 'react';
import { CssVarsProvider, useColorScheme, extendTheme, useTheme } from '@mui/joy/styles';
import { Select, Option, Sheet, Typography, Button, Box, Menu, MenuItem, IconButton } from '@mui/joy';
import ContrastIcon from '@mui/icons-material/Contrast';
import LightModeIcon from '@mui/icons-material/LightMode';

function ModeSwitcher() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }
  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  }
  {/* <IconButton
      variant='soft'
      sx={{ justifyContent: 'center', transition: 'transform 0.3s' }}
      fullWidth
      color='primary'
      onClick={toggleMode}
    >
      {mode === 'light' ? <ContrastIcon /> : <LightModeIcon />}
    </IconButton> */}
  return (
    null
    
  );
}

// Extensión del tema para soportar light y dark mode
const customTheme = extendTheme({
  cssVarPrefix: 'mode-toggle',
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: '#1976d2',
          solidHoverBg: '#1565c0',
        },
        neutral: {
          solidBg: '#f5f5f5',
          solidHoverBg: '#e0e0e0',
        },
        background: {
          body: '#ffffff',
        },
      },
      typography: {
        fontFamily: 'Roboto, sans-serif',
      },
    },
    dark: {
      palette: {
        background: {
          body: '#2c2c2c', // Gris oscuro
        },
        primary: {
          solidBg: '#444751',
          solidHoverBg: '#64b5f6',
        },
        neutral: {
          solidBg: '#444751',
          solidHoverBg: '#424242',
        },
      },
      typography: {
        fontFamily: 'Roboto, sans-serif',
      },
    },
  },
  components: {
    Typography: {
      styleOverrides: {
        h2: {
          fontSize: '2rem',
          fontWeight: 700,
        },
        h4: {
          fontSize: '1.5rem',
          fontWeight: 700,
        },
        body: {
          fontSize: '1rem',
          fontWeight: 400,
        },
      },
    },
    Button: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          transition: 'background-color 0.3s',
        },
      },
    },
  },
});

function App() {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuAnchorRef = React.useRef(null);
  const theme = useTheme();

  return (
    <CssVarsProvider
      theme={customTheme}
      modeStorageKey="mode-toggle-demo"
      disableNestedContext
    >
      {/* Barra de navegación */}
      <Sheet
        variant="soft"
        color="primary"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          py: 1,
          backgroundColor: theme.palette.background.body,
          transition: 'background-color 0.5s ease',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={goliat}
            alt="Goliat logo al óleo"
            style={{ width: '50px', borderRadius: 10, marginRight: 10 }}
          />
          <Typography level="h4" sx={{ fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}>
            ERP Goliat
          </Typography>
        </Box>
        {/* Menú en pantallas grandes */}
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            alignItems: 'center',
          }}
        >
          <Button
          onClick={() => window.open('https://wa.me/2211214153')}
            variant="soft"
            color="success"
            sx={{
              mr: 1,
              borderRadius: '8px',
              padding: { xs: '12px 16px', sm: '8px 12px' },
              fontSize: { xs: '1rem', sm: '0.875rem' },
            }}
          >
            Contactanos
          </Button>
          <Button
          onClick={() => window.open('https://erpgoliat.com/paternalia')}
            variant="soft"
            color="primary"
            sx={{
              mr: 1,
              borderRadius: '8px',
              padding: { xs: '12px 16px', sm: '8px 12px' },
              fontSize: { xs: '1rem', sm: '0.875rem' },
            }}
          >
            Inicio de sesión
          </Button>
          <ModeSwitcher />
        </Box>
        {/* Menú desplegable en pantallas pequeñas */}
        <Box
          sx={{
            display: {
              xs: 'flex',
              sm: 'none',
            },
            alignItems: 'center',
          }}
        >
          <Button
            ref={menuAnchorRef}
            variant="soft"
            color="neutral"
            onClick={() => setMenuOpen(true)}
            sx={{
              borderRadius: '8px',
              padding: '8px 12px',
              fontSize: '1rem',
            }}
          >
            ☰
          </Button>
          <Menu
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            anchorEl={menuAnchorRef.current}
            placement="bottom-end"
            sx={{ minWidth: 200 }}
          >
            <MenuItem onClick={() => setMenuOpen(false)}>
              <Button
                variant="soft"
                color="success"
                fullWidth
                sx={{
                  borderRadius: '8px',
                  padding: '8px 12px',
                }}
              >
                Contactanos
              </Button>
            </MenuItem>
            <MenuItem onClick={() => setMenuOpen(false)}>
              <Button
              onClick={() => window.open('https://erpgoliat.com/paternalia')}
                variant="soft"
                color="primary"
                fullWidth
                sx={{
                  borderRadius: '8px',
                  padding: '8px 12px',
                }}
              >
                Inicio de sesión
              </Button>
            </MenuItem>
            <MenuItem
              sx={{
                alignContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
              onClick={() => setMenuOpen(false)}
            >
              <ModeSwitcher />
            </MenuItem>
          </Menu>
        </Box>
      </Sheet>

      {/* Contenido principal */}
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          p: { xs: 4, sm: 10 },
          transition: 'background-color 0.5s ease',
        }}
      >
        <Box
          component="img"
          src={graficaEstambres}
          alt="Descripción de la imagen"
          sx={{
            borderRadius: 30,
            width: { xs: '100%', sm: '50%' },
            mb: { xs: 2, sm: 0 },
            mt: { xs: 2, sm: 0, md: 0, lg: 0 },
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            mr: { sm: 4 },
            animation: 'fadeIn 2s ease-in-out',
            '@keyframes fadeIn': {
              '0%': { opacity: 0, transform: 'translateX(-20px)' },
              '100%': { opacity: 1, transform: 'translateX(0)' },
            },
          }}
        />
        <Box
          sx={{
            width: { xs: '100%', sm: '50%' },
            maxWidth: 500,
            textAlign: 'center',
          }}
        >
          <Typography
            level="h2"
            sx={{
              mb: 2,
              animation: 'fadeIn 2s ease-in-out',
              '@keyframes fadeIn': {
                '0%': { opacity: 0, transform: 'translateX(20px)' },
                '100%': { opacity: 1, transform: 'translateX(0)' },
              },
            }}
          >
            Mejoramos tu gestión empresarial
          </Typography>
          <Typography sx={{ fontSize: '1rem' }}>
            En <strong style={{ color: '#FFA500' }}>ERP Goliat</strong>, ofrecemos soluciones integrales para mejorar tus procesos de negocio con nuestras herramientas de <strong style={{ color: '#FFA500' }}>ERP</strong>, <strong style={{ color: '#FFA500' }}>SAE</strong> y <strong style={{ color: '#FFA500' }}>CRM</strong>. Optimiza la eficiencia y productividad de tu empresa con nuestras soluciones personalizadas.
          </Typography>
        </Box>
      </Sheet>
    </CssVarsProvider>
  );
}

export default App;
